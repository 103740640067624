// src/authConfig.js
// export const msalConfig = {
//     auth: {
//         clientId: "b9a02714-7519-4633-99b3-52c5d0c6feef",
//         // authority: "https://login.microsoftonline.com/88f558f5-a216-470b-b34a-3164f5d6ec0f",
//         authority: " https://login-qa.ciam.daimlertruck.com/88f558f5-a216-470b-b34a-3164f5d6ec0f/b2c_1a_signin_oidc_row",
//         knownAuthorities: ['login-qa.ciam.daimlertruck.com'],
//         redirectUri: "https://localhost:3000/home",
//         navigateToLoginRequestUrl: true,
//     },
//     cache: {
//         cacheLocation: "localStorage",
//         storeAuthStateInCookie: true,
//     }
// };

// export const loginRequest = {
//     scopes: ["openid", "profile", "email"]
// };


export const loginRequest = {
    //scopes: ['openid', 'b9d7fdae-b7d9-462a-a61d-b3e4138b1fe8'] // Production
    scopes: ['openid', 'b9a02714-7519-4633-99b3-52c5d0c6feef'] // UAT
};

export const msalConfig = {
    auth: {
         clientId: 'b9a02714-7519-4633-99b3-52c5d0c6feef', // UAT client ID
         authority: 'https://login-qa.ciam.daimlertruck.com/88f558f5-a216-470b-b34a-3164f5d6ec0f/b2c_1a_signin_oidc_row', //UAT
         knownAuthorities: ['login-qa.ciam.daimlertruck.com'], //UAT
         redirectUri:'https://uat.rakshana.bharatbenz.com/home', //UAT

        // clientId: 'b9d7fdae-b7d9-462a-a61d-b3e4138b1fe8', // Production client ID
        // authority: 'https://login.ciam.daimlertruck.com/3db550f0-0c7f-439b-8e24-e32bf233615d/b2c_1a_signin_oidc_row', //Production
        // knownAuthorities: ['login.ciam.daimlertruck.com'], //Production
        // redirectUri: 'https://www.rakshana.bharatbenz.com/home', //Production

        // redirectUri: 'https://localhost:3000/home',  // Replace with your redirect URI
        //redirectUri: 'https://uptimecenter.airlinq.com/home', //QA

    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to true if you are having issues on IE11 or Edge
    }
};

