import {
    NAV_ITEM_TYPE_TITLE,
    NAV_ITEM_TYPE_COLLAPSE,
    NAV_ITEM_TYPE_ITEM,
} from 'constants/navigation.constant'
import { useSelector } from 'react-redux'

let navigationConfigFile = [
    // {
    //     key: 'dashboard',
    //     path: '/dashboard',
    //     title: 'DASHBOARD',
    //     translateKey: 'nav.dashboard',
    //     icon: 'dashboard',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'DASHBOARD',
    // },


    // {
    //     key: 'mapview',
    //     path: '/mapview',
    //     title: 'Map View',
    //     translateKey: 'nav.mapviewMenu.mapviewMenu',
    //     icon: 'mapView',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'MAPV',
    //     subMenu: [
    //         {
    //             key: 'mapviewMenu.item1',
    //             path: '/mapview-menu-item',
    //             title: 'Dealer Map',
    //             translateKey: 'nav.mapviewMenu.item1',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'DMAP',
    //         },
    //         {
    //             key: 'mapviewMenu.item2',
    //             path: '/mapview-menu-item2',
    //             title: 'Truck Map',
    //             translateKey: 'nav.mapviewMenu.item2',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TMAP',
    //         },
    //     ],
    // },
    // {
    //     key: 'dtcDashBoard',
    //     path: '/dtcDashBoard',
    //     title: 'DTC Dashboard',
    //     translateKey: 'nav.dtcDashboardMenu.dtcDashboardMenu',
    //     icon: 'dashboard',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'DTCD',
    //     subMenu: [
    //         {
    //             key: 'dtcDashboardMenu.item1',
    //             path: '/dtcDashboard-menu-item1',
    //             title: 'DTC Dashboard',
    //             translateKey: 'nav.dtcDashboardMenu.item1',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'DTC',
    //         },

    //     ],
    // },
    // {
    //     key: 'reportsDashBoard',
    //     path: '/reportsDashBoard',
    //     title: 'Report Dashboard',
    //     translateKey: 'nav.reportDashBoardMenu.reportDashBoardMenu',
    //     icon: 'reports',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'REPD',
    //     subMenu: [
    //         {
    //             key: 'reportDashBoardMenu.item1',
    //             path: '/reportDashBoard-menu-item1',
    //             title: 'Reports',
    //             translateKey: 'nav.reportDashBoardMenu.item1',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'REP',
    //         },

    //     ],
    // },
    // {
    //     key: 'canparameters',
    //     path: '/canparameters',
    //     title: 'CAN Parameters',
    //     translateKey: 'nav.canParametersMenu.canParametersMenu',
    //     icon: 'parameters',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'CANP',
    //     subMenu: [
    //         {
    //             key: 'canParametersMenu.item1',
    //             path: '/canParametersMenu-menu-item1',
    //             title: 'CAN Parameters',
    //             translateKey: 'nav.canParametersMenu.item1',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'CAN',
    //         },

    //     ],
    // },
    // {
    //     key: 'redMine',
    //     path: '/redMine',
    //     title: 'redMine',
    //     translateKey: 'nav.redmineMenu.redmineMenu',
    //     icon: 'home',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'MM',
    // },
    // {
    //     key: 'home',
    //     path: '/dashboard-management',
    //     title: 'Home',
    //     translateKey: 'nav.dashboard',
    //     icon: 'dashboard',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    // {
    //     key: 'Dashboard Managment',
    //     path: '/dashboard-management',
    //     title: 'Dashboard',
    //     translateKey: 'nav.DashboardMenu.DashboardMenu',
    //     icon: 'dashboard',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    {
        key: 'Dashboard Managment',
        path: '/Dashboard',
        title: 'Dashboard',
        translateKey: 'nav.DashboardMenu.DashboardMenu',
        icon: 'dashboard',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        code: 'OPR',
        subMenu: [
            {
                key: 'HQ Dashboard',
                path: '/dashboard-management',
                title: 'HQ Dashboard',
                translateKey: 'nav.DashboardMenu.HQDashboard',
                // icon: 'upload',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
                code: 'HQD',
            }
        ],
    },
    // {
    //     key: 'trackandTraceMenu.item1',
    //     path: '/track-trace',
    //     title: 'Track and Trace',
    //     translateKey: 'nav.trackandTraceMenu.trackandTraceMenu',
    //     icon: 'location',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    {
        key: 'trackandTraceMenu.item1',
        path: '/track-and-trace',
        title: 'Track and Trace',
        translateKey: 'nav.trackandTraceMenu.trackandTraceMenu',
        icon: 'location',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        code: 'TAT',
        subMenu: [
            {
                key: 'Live Tracking',
                path: '/track-trace',
                title: 'Live Tracking',
                translateKey: 'nav.trackandTraceMenu.liveTracking',
                // icon: 'upload',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
                code: 'TTS',
            }
        ],
    },
    {
        key: 'user',
        path: '/account',
        title: 'ACCOUNTS',
        translateKey: 'nav.accountMenu.accountMenu',
        icon: 'group',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: ['ACCD'],
        code: 'ACC',
        subMenu: [
            {
                key: 'accountMenu.item1',
                path: '/account-menu-item-view-1/users',
                title: 'My Account',
                translateKey: 'nav.accountMenu.item1',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: ['USERS'],
                subMenu: [],
                code: 'MYA',
            },

        ],
    },
    
    // {
    //     key: 'operations',
    //     path: '/operations',
    //     title: 'OPERATIONS',
    //     translateKey: 'nav.operationsMenu.operationsMenu',
    //     icon: 'fleet',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'OPR',
    //     subMenu: [
    //         {
    //             key: 'operationsMenu.item1',
    //             path: '/operationsMenu-menu-item1',
    //             title: 'Vehicle Dashboard',
    //             translateKey: 'nav.operationsMenu.item1',
    //             icon: 'uptime',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'VDB',
    //         },
    //         // {
    //         //     key: 'operationsMenu.item2',
    //         //     path: '/operationsMenu-menu-item2',
    //         //     title: 'Dtc Dashboard',
    //         //     translateKey: 'nav.operationsMenu.item2',
    //         //     icon: 'dtc',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'DTCDB',
    //         // },

    //         {
    //             key: 'operationsMenu.item4',
    //             path: '/can_dashboard',
    //             title: 'CAN Dashboard',
    //             translateKey: 'nav.operationsMenu.item4',
    //             icon: 'can',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'CAN',
    //         },
    //         // {
    //         //     key: 'RedMine',
    //         //     path: '/RedMine',
    //         //     title: 'Ticket Dashboard',
    //         //     translateKey: 'nav.operationsMenu.item5',
    //         //     icon: '',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'TDB',
    //         // },
    //         {
    //             key: 'Exception',
    //             path: '/Exception',
    //             title: 'Exception Dashboard',
    //             translateKey: 'nav.operationsMenu.item6',
    //             icon: 'exception',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'EXE',
    //         },
    //         {
    //             key: 'Redmine',
    //             path: '/Redmine',
    //             title: 'Ticket Dashboard',
    //             translateKey: 'nav.operationsMenu.item9',
    //             icon: 'ticket',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TDB',
    //         },
    //         {
    //             key: 'Ticket Details',
    //             path: '/ticket-details',
    //             title: 'Ticket Details',
    //             translateKey: 'nav.operationsMenu.item10',
    //             icon: 'ticket',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TID',
    //         },
    //         // {
    //         //     key: 'Ticket Details',
    //         //     path: '/ticket-details',
    //         //     title: 'Ticket Details',
    //         //     translateKey: 'nav.operationsMenu.item7',
    //         //     icon: '',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'TID',
    //         // },
    //         // {
    //         //     key: 'Analytics',
    //         //     path: '/Analytics',
    //         //     title: 'T Dashboard',
    //         //     translateKey: 'nav.operationsMenu.item8',
    //         //     icon: '',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'ANA',
    //         // },

    //     ],
    // },
    // {
    //     key: 'Vehicle Tracking',
    //     path: '/vehicle-tracking',
    //     title: 'Vehicle Tracking',
    //     translateKey: 'nav.trackingMenu.trackingMenu',
    //     icon: 'reports',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    // {
    //     key: 'Ticketing',
    //     path: '/Ticketing',
    //     title: 'Ticketing',
    //     translateKey: 'nav.ticketing.ticketingMenu',
    //     icon: 'copilot',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'TIC',
    //     subMenu: [
    //         {
    //             key: 'Redmine',
    //             path: '/Redmine',
    //             title: 'Ticket Dashboard',
    //             translateKey: 'nav.ticketing.item1',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TDB',
    //         },
    //         {
    //             key: 'Ticket Details',
    //             path: '/ticket-details',
    //             title: 'Ticket Details',
    //             translateKey: 'nav.ticketing.item2',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TID',
    //         },

    //     ],
    // },
    // {
    //     key: 'Analytics',
    //     path: '/analytics',
    //     title: 'Analytics',
    //     translateKey: 'nav.analyticsMenu.analyticsMenu',
    //     icon: 'analytics',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'CPI',
    //     subMenu: [
    //         {
    //             key: 'Predictive Analytics',
    //             path: '/predictive-analytics',
    //             title: 'Predictive Analytics',
    //             translateKey: 'nav.analyticsMenu.item1',
    //             icon: 'predictive',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TID',
    //         },
    //         {
    //             key:"CAN ParametersMenu",
    //             path:"/can-parameters",
    //             title:"CAN Data Live",
    //             translateKey: '',
    //             icon: 'reports',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'TID',
    //         }
    //         // {
    //         //     key: 'Tickets',
    //         //     path: '/Tickets',
    //         //     title: 'Tickets',
    //         //     translateKey: 'nav.analyticsMenu.tickets',
    //         //     icon: 'tickets',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'TID',
    //         // },
    //         // {
    //         //     key: 'Alerts',
    //         //     path: '/Alerts',
    //         //     title: 'Alerts',
    //         //     translateKey: 'nav.analyticsMenu.alerts',
    //         //     icon: 'alerts',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'TID',
    //         // },
    //         // {
    //         //     key: 'Performance',
    //         //     path: '/Performance',
    //         //     title: 'Performance',
    //         //     translateKey: 'nav.analyticsMenu.performance',
    //         //     icon: 'performance',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'TID',
    //         // },
    //     ],
    // },
    // {
    //     key: 'copilot',
    //     path: '/copilot',
    //     title: 'OPERATIONS',
    //     translateKey: 'nav.copilotMenu.copilotMenu',
    //     icon: 'robot',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'CPI',
    //     subMenu: [
    //         {
    //             key: 'customer service agent',
    //             path: '/customer-service-agent',
    //             title: 'customer service agent',
    //             translateKey: 'nav.truckOwner.customerServiceAgent',
    //             icon: 'ticket',
    //             type: NAV_ITEM_TYPE_COLLAPSE,
    //             authority: [],
    //             subMenu: [],
    //             code: 'DTCA',
    //         },
    //         {
    //             key: 'Service Technician',
    //             path: '/service-Technician',
    //             title: 'Service Technician',
    //             translateKey: 'nav.truckOwner.serviceTechnician',
    //             icon: 'serviceTechnician',
    //             type: NAV_ITEM_TYPE_COLLAPSE,
    //             authority: [],
    //             subMenu: [],
    //             code: 'DTCA',
    //         },
    //         {
    //             key: 'Fleet Owner',
    //             path: '/fleet-Owner',
    //             title: 'Fleet Owner',
    //             translateKey: 'nav.truckOwner.fleetOwner',
    //             icon: 'fleetOwner',
    //             type: NAV_ITEM_TYPE_COLLAPSE,
    //             authority: [],
    //             subMenu: [],
    //             code: 'DTCA',
    //         },
 
    //         // {
    //         //     key: 'Truck Owner',
    //         //     path: '/truck-owner',
    //         //     title: 'Truck Owner',
    //         //     translateKey: 'nav.copilotMenu.truckOwner',
    //         //     icon: '',
    //         //     type: NAV_ITEM_TYPE_ITEM,
    //         //     authority: [],
    //         //     subMenu: [],
    //         //     code: 'TRUCK',
    //         // },
    //     ],
    // },
 
    // {
    //     key: 'Contact Us',
    //     path: '/Contact Us',
    //     title: 'DASHBOARD',
    //     translateKey: 'nav.contactMenu.contactMenu',
    //     icon: 'contact',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    // {
    //     key: 'CAN ParametersMenu',
    //     path: '/can-parameters',
    //     title: 'CAN Parameters',
    //     translateKey: 'nav.CANParameters.CANParameters',
    //     icon: 'reports',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [
    //         {
    //             key:"CAN ParametersMenu",
    //             path:"/can-parameters",
    //             title:"Can Parameters",
    //             translateKey: '',
    //             icon: 'reports',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'CPI',
    //         }
    //     ],
    //     code: 'HOM',
    // },
    // {
    //     key: 'trackandTraceMenu.item1',
    //     path: '/track-trace',
    //     title: 'Track and Trace',
    //     translateKey: 'nav.trackandTraceMenu.trackandTraceMenu',
    //     icon: 'location',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    {
        key: 'alertsManagement',
        path: '/alertsManagement',
        title: 'Alerts',
        translateKey: 'nav.alertsMenu.alertsMenu',
        icon: 'dtcalerts',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        code: 'ALR',
        subMenu: [
            {
                key: 'alertsManagement.item1',
                path: '/operationsMenu-menu-item2',
                title: 'DTC Alerts',
                translateKey: 'nav.alertsMenu.item1',
                icon: 'dtc',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
                code: 'DTCDB',
            },
        ],
    },
    // {
    //     key: 'Tickets',
    //     path: '/viewtickets',
    //     title: 'Tickets',
    //     translateKey: 'nav.analyticsMenu.tickets',
    //     icon: 'flag',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    {
        key: 'Tickets',
        path: '/Tickets',
        title: 'Tickets',
        translateKey: 'nav.analyticsMenu.tickets',
        icon: 'flag',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        code: 'TKT',
        subMenu: [
            {
                key: 'Tickets List',
                path: '/viewtickets',
                title: 'Tickets List',
                translateKey: 'nav.analyticsMenu.subtickets',
                // icon: 'upload',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
                code: 'TLS',
            }
        ],
    },
    {
        key: 'mastermanagement',
        path: '/mastermanagement',
        title: 'Master Data Management',
        translateKey: 'nav.masterManagementMenu.masterManagementMenu',
        icon: 'openFolder',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        code: 'MM',
        subMenu: [
            {
                key: 'mastermanagement.item1',
                path: '/masterManagement-menu-item1',
                title: 'Upload',
                translateKey: 'nav.masterManagementMenu.item1',
                // icon: 'upload',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
                code: 'MUP',
            },
            {
                key: 'mastermanagement.item2',
                path: '/masterManagement-menudata-item2',
                title: 'Download',
                translateKey: 'nav.masterManagementMenu.item2',
                // icon: 'download',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
                code: 'MDV',
            }
        ],
    },
    {
        key: 'About Us',
        path: '/About Us',
        title: 'DASHBOARD',
        translateKey: 'nav.aboutMenu.aboutMenu',
        icon: 'aboutus',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
        code: 'DEF',
    },
    {
        key: 'Contact Us',
        path: '/Contact Us',
        title: 'ContactUs',
        translateKey: 'nav.contactMenu.contactMenu',
        icon: 'contactUs',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
        code: 'DEF',
    },
   
    // {
    //     key: 'CCAgent TL',
    //     path: '/ccagent-tl',
    //     title: 'DEALER',
    //     translateKey: 'nav.ccAgentTLMenu.ccAgentTLMenu',
    //     icon: 'reports',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },
    // {
    //     key: 'Dealer TL',
    //     path: '/dealer-tl',
    //     title: 'DEALER',
    //     translateKey: 'nav.dealerTLMenu.dealerTLMenu',
    //     icon: 'reports',
    //     type: NAV_ITEM_TYPE_ITEM,
    //     authority: [],
    //     subMenu: [],
    //     code: 'HOM',
    // },

    // {
    //     key: 'copilot',
    //     path: '/copilot',
    //     title: 'COPILOT',
    //     translateKey: 'nav.copilotMenu.copilotMenu',
    //     icon: 'dashboard',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'COP',
    //     subMenu: [
    //         {
    //             key: 'copilotMenu.item1',
    //             path: '/copilotMenu-menu-item1',
    //             title: 'DTC Analytics',
    //             translateKey: 'nav.copilotMenu.item1',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'DA',
    //         },
    //         {
    //             key: 'copilotMenu.item2',
    //             path: '/copilotMenu-menu-item2',
    //             title: 'CAN Analytics',
    //             translateKey: 'nav.copilotMenu.item2',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'CA',
    //         },
    //         {
    //             key: 'copilotMenu.item3',
    //             path: '/copilotMenu-menu-item3',
    //             title: 'Predicitve Analytics',
    //             translateKey: 'nav.copilotMenu.item3',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'PA',
    //         },
    //          {
    //             key: 'copilotMenu.item4',
    //             path: '/copilotMenu-menu-item4',
    //             title: 'Chat',
    //             translateKey: 'nav.copilotMenu.item4',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //             code: 'Chat',
    //         },

    //     ],
    // },

    // {
    //     key: 'settings',
    //     path: '/settings',
    //     title: 'SETTINGS',
    //     translateKey: 'nav.settingsMenu.settingsMenu',
    //     icon: 'settings',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     code: 'SET',
    //     subMenu: [
    //         {
    //             key: 'settingsMenu.passwordPolicy',
    //             path: '/settings-menu-password-policy',
    //             title: 'Password Policy',
    //             translateKey: 'nav.settingsMenu.passwordPolicy',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             code: 'PPC',
    //             subMenu: [],
    //         }

    //     ],
    // },


]

// const navigationConfig = [
//     {
//         key: 'dashboard',
//         path: '/dashboard',
//         title: 'DASHBOARD',
//         translateKey: 'nav.dashboard',
//         icon: 'dashboard',
//         type: NAV_ITEM_TYPE_ITEM,
//         authority: [],
//         subMenu: [],
//         code: 'DASHBOARD',
//     },
//     /** Example purpose only, please remove */
//     {
//         key: 'user',
//         path: '/account',
//         title: 'ACCOUNT',
//         translateKey: 'nav.accountMenu.accountMenu',
//         icon: 'user',
//         type: NAV_ITEM_TYPE_COLLAPSE,
//         authority: ['ACCD'],
//         code: 'ACC',
//         subMenu: [
//             {
//                 key: 'accountMenu.item1',
//                 path: '/account-menu-item-view-1',
//                 title: 'My Account',
//                 translateKey: 'nav.accountMenu.item1',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: ['USERS'],
//                 subMenu: [],
//                 code: 'MYA',
//             },
//             {
//                 key: 'accountMenu.item2',
//                 path: '/account-menu-item-view-2',
//                 title: 'Operators',
//                 translateKey: 'nav.accountMenu.item2',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//                 code: 'OPR',
//             },

//             {
//                 key: 'accountMenu.item3',
//                 path: '/account-menu-item-view-3',
//                 title: 'Providers',
//                 translateKey: 'nav.accountMenu.item3',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'accountMenu.item4',
//                 path: '/account-menu-item-view-4',
//                 title: 'Partners',
//                 translateKey: 'nav.accountMenu.item4',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'accountMenu.item5',
//                 path: '/account-menu-item-view-5',
//                 title: 'Enterprise Customers',
//                 translateKey: 'nav.accountMenu.item5',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'accountMenu.item6',
//                 path: '/account-menu-item-view-6',
//                 title: 'Retail Customers',
//                 translateKey: 'nav.accountMenu.item6',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'accountMenu.item7',
//                 path: '/account-menu-item-view-7',
//                 title: 'Pending Approvals',
//                 translateKey: 'nav.accountMenu.item7',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//         ],
//     },
//     {
//         key: 'catalouge',
//         path: '/catalouge',
//         title: 'CATALOUGE',
//         translateKey: 'nav.catalougeMenu.catalougeMenu',
//         icon: 'catalouge',
//         type: NAV_ITEM_TYPE_COLLAPSE,
//         authority: [],
//         code: 'CAT',
//         subMenu: [
//             {
//                 key: 'catalougeMenu.item1',
//                 path: '/catalouge-menu-item-view-1',
//                 title: 'Templates',
//                 translateKey: 'nav.catalougeMenu.item1',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'catalougeMenu.item2',
//                 path: '/catalouge-menu-item-view-2',
//                 title: 'Items',
//                 translateKey: 'nav.catalougeMenu.item2',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'catalougeMenu.item3',
//                 path: '/catalouge-menu-item-view-3',
//                 title: 'Solutions',
//                 translateKey: 'nav.catalougeMenu.item3',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//         ],
//     },
//     {
//         key: 'wareHouse',
//         path: '/warehouse',
//         title: 'WAREHOUSE',
//         translateKey: 'nav.wareHouseMenu.wareHouseMenu',
//         icon: 'wareHouse',
//         type: NAV_ITEM_TYPE_COLLAPSE,
//         authority: [],
//         subMenu: [
//             {
//                 key: 'wareHouseMenu.item1',
//                 path: '/wareHouse-dashboard',
//                 title: 'Dashboard',
//                 translateKey: 'nav.wareHouseMenu.item1',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'wareHouseMenu.item2',
//                 path: '/wareHouse-menu-item-view-2',
//                 title: 'Warehouse',
//                 translateKey: 'nav.wareHouseMenu.item2',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'wareHouseMenu.item3',
//                 path: '/warehouse-workorder',
//                 title: 'Work Orders',
//                 translateKey: 'nav.wareHouseMenu.item3',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//         ],
//     },
//     {
//         key: 'orders',
//         path: '/orders',
//         title: 'ORDERS',
//         translateKey: 'nav.ordersMenu.ordersMenu',
//         icon: 'orders',
//         type: NAV_ITEM_TYPE_ITEM,
//         authority: [],
//         subMenu: [],
//     },
//     {
//         key: 'offers',
//         path: '/offers',
//         title: 'OFFERS & CASHBACK',
//         translateKey: 'nav.offersMenu.offersMenu',
//         icon: 'offers',
//         type: NAV_ITEM_TYPE_ITEM,
//         authority: [],
//         subMenu: [],
//     },
//     {
//         key: 'billing',
//         path: '/billing',
//         title: 'BILLING',
//         translateKey: 'nav.billingMenu.billingMenu',
//         icon: 'billing',
//         type: NAV_ITEM_TYPE_COLLAPSE,
//         authority: [],
//         subMenu: [
//             {
//                 key: 'billingMenu.dashboard',
//                 path: '/billing-dashboard',
//                 title: 'Dashboard',
//                 translateKey: 'nav.billingMenu.dashboard',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'billingMenu.chargeAndBills',
//                 path: '/billing-charge-and-bills',
//                 title: 'Charge & Bills',
//                 translateKey: 'nav.billingMenu.chargeAndBills',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'billingMenu.payments',
//                 path: '/billing-payments',
//                 title: 'Payments',
//                 translateKey: 'nav.billingMenu.payments',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'billingMenu.settlements',
//                 path: '/billing-settlements',
//                 title: 'Settlements',
//                 translateKey: 'nav.billingMenu.settlements',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//         ],
//     },
//     {
//         key: 'reports',
//         path: '/reports',
//         title: 'REPORTS',
//         translateKey: 'nav.reportsMenu.reportsMenu',
//         icon: 'reports',
//         type: NAV_ITEM_TYPE_ITEM,
//         authority: [],
//         subMenu: [],
//     },
//     {
//         key: 'settings',
//         path: '/settings',
//         title: 'SETTINGS',
//         translateKey: 'nav.settingsMenu.settingsMenu',
//         icon: 'settings',
//         type: NAV_ITEM_TYPE_COLLAPSE,
//         authority: [],
//         subMenu: [
//             {
//                 key: 'settingsMenu.generalSettings',
//                 path: '/settings-menu-general-settings',
//                 title: 'General Settings',
//                 translateKey: 'nav.settingsMenu.generalSettings',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'settingsMenu.apiConfiguration',
//                 path: '/settings-menu-api-configuration',
//                 title: 'API Configuration',
//                 translateKey: 'nav.settingsMenu.apiConfiguration',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'settingsMenu.themeConfiguration',
//                 path: '/settings-menu-theme-configuraion',
//                 title: 'Theme Configuration',
//                 translateKey: 'nav.settingsMenu.themeConfiguration',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'settingsMenu.brandInfo',
//                 path: '/settings-menu-brand-info',
//                 title: 'Brand Info',
//                 translateKey: 'nav.settingsMenu.brandInfo',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'settingsMenu.notifications',
//                 path: '/settings-menu-notifications',
//                 title: 'Notifications',
//                 translateKey: 'nav.settingsMenu.notifications',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'settingsMenu.manageWebSite',
//                 path: '/settings-menu-manage-web-site',
//                 title: 'Manage WebSite',
//                 translateKey: 'nav.settingsMenu.manageWebSite',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//         ],
//     },
//     // MasterData
//     {
//         key: 'masterData',
//         path: '/masterdata',
//         title: 'Master Data',
//         translateKey: 'nav.masterDataMenu.masterDataMenu',
//         icon: 'masterData',
//         type: NAV_ITEM_TYPE_COLLAPSE,
//         authority: [],
//         subMenu: [
//             {
//                 key: 'masterDataMenu.item1',
//                 path: '/masterDataMenu-item-view-1',
//                 title: 'Doc Type',
//                 translateKey: 'nav.masterDataMenu.item1',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'masterDataMenu.item2',
//                 path: '/masterDataMenu-item-view-2',
//                 title: 'Product Category',
//                 translateKey: 'nav.masterDataMenu.item2',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'masterDataMenu.item3',
//                 path: '/masterDataMenu-item-view-3',
//                 title: 'Customer Category',
//                 translateKey: 'nav.masterDataMenu.item3',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'masterDataMenu.item4',
//                 path: '/masterDataMenu-item-view-4',
//                 title: 'Tax Component',
//                 translateKey: 'nav.masterDataMenu.item4',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'masterDataMenu.item5',
//                 path: '/masterDataMenu-item-view-5',
//                 title: 'currency',
//                 translateKey: 'nav.masterDataMenu.item5',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//             {
//                 key: 'masterDataMenu.item6',
//                 path: '/masterDataMenu-item-view-6',
//                 title: 'Contract Type',
//                 translateKey: 'nav.masterDataMenu.item6',
//                 icon: '',
//                 type: NAV_ITEM_TYPE_ITEM,
//                 authority: [],
//                 subMenu: [],
//             },
//         ],
//     },
// ]

export default navigationConfigFile
