import { userRoles } from "utils/constants";
import ApiService from "./ApiService";
export async function apiSearchbyALL(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters/alerts-suggestion-search/${params}`,
        method: 'get',
        // params,
    })
}
export async function ApigetSpocSugessionData(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/spoc-ticket-suggestions`,
        method: 'get',
        params,
    })
}
export async function ApigetExecutiveSugessionData(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/executive-ticket-suggestions`,
        method: 'get',
        params,
    })
}

export async function apiSearchbyVIN(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters-search-suggestions/${params}`,
        method: 'get',
        // params,
    })
}

export async function apiSearchbyRegn(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters/regn-search/${params}`,
        method: 'get',
        // params,
    })
}
export async function apiSpocSearchbyRegn(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/regis-no/spoc-ticket-suggestions`,
        method: 'get',
        params,
    })
}
export async function apiExecutiveSearchbyRegn(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/regno-executive-ticket-suggestions`,
        method: 'get',
        params,
    })
}
export async function apiSearchbyCustName(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters/suggestions-by-custname/${params}`,
        method: 'get',
        // params,
    })
}
export async function apiFetchDealerCity(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/city-dropdown`,
        method: 'get',
        // params,
    })
}
export async function apiSearchbyDealerName(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/dealer-name/search?key=${params}`,
        method: 'get',
        // params,
    })
}
export async function apiSearchbyPlantCode(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/plant-code/search?key=${params}`,
        method: 'get',
        // params,
    })
}
export async function apiSearchbyIMEI(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters/imei-search/${params}`,
        method: 'get',
        // params,
    })
}

export async function apiSearchbyVarient(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters/suggestions-by-varient/${params}`,
        method: 'get',
        // params,
    })
}

export async function updateApiSearchfilter(data, id) {
    try {
        const resp = await ApiService.fetchData({
            url: `operation/search-filter/${id}`,
            method: 'put',
            data,
        })
        return {
            status: 'success',
            data: resp,
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data || errors.toString(),
        }
    }
}

export async function apiMasterDataSearchFilterName(params) {
    return ApiService.fetchData({
        url: 'operation/search-filter/user',
        method: 'get',
        params,
    })
}

export async function apimasterDealerSavedFilter(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/dropdown`,
        method: 'get',
        params
    })
}

export async function apiSearchbyVINFilter(params) {
    return ApiService.fetchData({
        url: `operation/vehicle-masters-search/${params}`,
        method: 'get',
        // params,
    })
}
export async function apiExecutiveSearchbyVINFilter(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/vin/executive-ticket-suggestions`,
        method: 'get',
        params,
    })
}
export async function apiSpocSearchbyVINFilter(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/vin/spoc-ticket-suggestions`,
        method: 'get',
        params,
    })
}
export async function apiRunningStatusFilter() {
    return ApiService.fetchData({
        url: 'operation/vehicle-masters/status-enum',
        method: 'get',
    })
}
export async function getSpocTicketDropdown() {
    return ApiService.fetchData({
        url: 'elastic/dealer-ticketing-dropdown',
        method: 'get',
    })
}
export async function getExcecutiveTicketDropdown() {
    return ApiService.fetchData({
        url: 'elastic/executive-ticket-dropdowns',
        method: 'get',
    })
}
export async function apiSegmentFilter() {
    return ApiService.fetchData({
        url: 'operation/vehicle-masters/vehicle-track/dropdown',
        method: 'get',
    })
}
export async function apiGetTicketingApproval(params) {
    return ApiService.fetchData({
        url: 'operation/approval/tckt-approvals',
        method: 'get',
        params
    })
}
export async function apiGetApprovalsDetails(params) {
    return ApiService.fetchData({
        url: 'operation/approval/type-base/tckt-approvals',
        method: 'get',
        params
    })
}
export async function apiGetFleetManager(vin) {
    return ApiService.fetchData({
        //url: 'operation/fleet-manager',
        url: `operation/vehicle-masters/fleet-mgr/${vin}`,
        method: 'get',
       // params
    })
}

export async function apiGetFleetDriver(params) {
    return ApiService.fetchData({
        url: 'operation/fleet-driver',
        method: 'get',
        params
    })
}
export async function updateTicketApprovalData(data) {
    try {
        const resp = await ApiService.fetchData({
            url: 'operation/approval',
            method: 'put',
            data,
        })
        return {
            status: 'success',
            data: resp,
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data || errors.toString(),
        }
    }
}
export async function apiTicketListData(params, roleType) {
    console.log(params, "params")
    let url = 'elastic/executive-ticketing/similar-search';
    // if(roleType=== userRoles.UPTIME_CENTRE_EXECUTIVE){
    //     url = 'elastic/dealer-ticketing/similar-search';
    // }else 
    if (roleType === userRoles.DEALER_SPOC) {
        url = 'elastic/dealer-ticketing/similar-search';
    }
    if (roleType === userRoles.DEALER_SPOC_MANAGER) {
        url = 'elastic/dealer-ticketing/similar-search';
    }
    // if(roleType == userRoles.UPTIME_CENTRE_ANALYST){
    //     url = 'elastic/executive-ticketing/similar-search';
    // }
    return ApiService.fetchData({
        url: url,
        method: 'get',
        params
    })
}

export async function apiAllExecutivesFilterData() {
    let url = 'account/acc-users/details'
    return ApiService.fetchData({
        url: url,
        method: 'get',
    })
}

export async function apiAllExecutivesFilterDataNewWithShiftData() {
    //let url = 'operation/shift-management/getExecutives-info'
    let url = 'operation/shift-management/getExecutives/shift-info'
    return ApiService.fetchData({
        url: url,
        method: 'get',
    })
}

export async function apiTicketingCount(params, roleType) {
    let url = 'elastic/executive-ticket-counts';
    // if(roleType=== userRoles.UPTIME_CENTRE_EXECUTIVE){
    //     url = 'elastic/dealer-ticketing/similar-search';
    // }else 
    if (roleType === userRoles.DEALER_SPOC || roleType === userRoles.DEALER_SPOC_MANAGER) {
        url = 'elastic/dealer-ticketing-count';
    }
    return ApiService.fetchData({
        url: url,
        method: 'get',
         params
    })
}

export async function apiTicketListCount() {
    return ApiService.fetchData({
        url: 'elastic/dtc-alerts-count',
        method: 'get',
        // params
    })
}

export async function apiFleetManagerDetails(ticketID) {
    return ApiService.fetchData({
        //url: `operation/fleet-manager/ticket/${ticketID}`,
        url: `operation/vehicle-masters/fleet-mgr/${ticketID}`,
        method: 'get',
    })
}

export async function apiAddFleetManager(data) {
    return ApiService.fetchData({
        // url: `operation/fleet-manager`,
        // method: 'post',
        url: `operation/vehicle-masters/fleet-mgr`,
        method: 'put',
        data,
    })
}

export async function apiDriverDetails(ticketID) {
    return ApiService.fetchData({
        url: `operation/fleet-driver/ticket/${ticketID}`,
        method: 'get',
    })
}

export async function apiAddDriver(data) {
    return ApiService.fetchData({
        url: `operation/fleet-driver`,
        method: 'post',
        data,
    })
}
export async function apiDtcAlertsDetails(ticketID) {
    return ApiService.fetchData({
        url: `operation/ticket-linkage/${ticketID}`,
        method: 'get',
    })
}

export async function apiGetDealerLocaton(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/location/${params}`,
        method: 'get',
        params
    })
}

export async function apiGetTechnicians(plant_code) {
    return ApiService.fetchData({
        url: `operation/dealer-technician/plant-code/${plant_code}`,
        method: 'get',
        // params
    })
}

export async function apiCreateTechnicians(data) {
    return ApiService.fetchData({
        url: `operation/dealer-technician`,
        method: 'post',
        data
    })
}


export async function apiGetDealers(pageSize, lati, longi, params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters-ticketing?page_no=0&page_size=${pageSize}&latitude=${lati}&longitude=${longi}`,
        method: 'get',
        params
    })
}

export async function apiGetDealersSearch(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters-ticketing`,
        method: 'get',
        params
    })
}

export async function apiUpdateIssueIdStatus(data) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/${data.issue_id}`,
        method: 'put',
        data
    })
}

export async function apiTicketAssignToTechnician(data) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/${data.issue_id}`,
        method: 'put',
        data
    })
}
export async function apiGetDealerSugessionsData(params) {
    return ApiService.fetchData({
        url: 'operation/dealers-masters/service-station/search',
        method: 'get',
        params
    })
}
export async function apiGetTechnicianData(params) {
    return ApiService.fetchData({
        url: 'operation/dealer-technician',
        method: 'get',
        params
    })
}
export async function apiGetTechinciansugession(params) {
    return ApiService.fetchData({
        url: `operation/dealer-technician/search/${params}`,
        method: 'get',
    })
}
export async function apiGetUserPlantCode(params) {
    return ApiService.fetchData({
        url: `operation/dealer-personals/dealer-code/email`,
        method: 'get',
        params
    })
}
export async function apiGetShiftRoasterData(params) {
    return ApiService.fetchData({
        url: `operation/shift-management`,
        method: 'get',
        params
    })
}
export async function apiGetDealerServicing(ticket_id) {
    return ApiService.fetchData({
        url: `operation/dealer-servicing/ticket?ticket_id=${ticket_id}`,
        method: 'get',
    })
}
export async function apiUpdateAlertEventDetails(data) {
    return ApiService.fetchData({
        url: `operation/ticket-linkage/${data.linkage_id}`,
        method: 'put',
        data
    })
}


export async function apiGetAlertEventEnums() {
    return ApiService.fetchData({
        url: `operation/alerts/status-enum`,
        method: 'get'
    })
}

export async function apiFetchShiftRoaster(params) {
    return ApiService.fetchData({
        url: `/operation/shift-management`,
        method: 'get',
        params
    })
}

export async function apiFetchAllEmpCodes() {
    return ApiService.fetchData({
        url: 'operation/shift-management/getAllEmpCodes',
        method: 'get',
    })
}

export async function apiFetchAllBranches(params) {
    return ApiService.fetchData({
        url: '/operation/dealers-masters',
        method: 'get',
        params
    })
}
export async function apiGetDealerOperationHours(params) {
    return ApiService.fetchData({
        url: `operation/dealer-operations/${params}`,
        method: 'get',
    })
}
export async function apiCreateDealerOperationalHours(data,apiType="post") {
    return ApiService.fetchData({
        url: `operation/dealer-operations`,
        method: apiType,
        data
    })
}
export async function apiFetchDealerSpocDetails(params) {
    return ApiService.fetchData({
        url: `operation/dealer-personals/dealer-code/email?email=${params}`,
        method: 'get',
    })
}

export async function apiSaveFilter(data) {
    return ApiService.fetchData({
        url: `/operation/search-filter`,
        method: 'post',
        data
    })
}

export async function apiReasons(ticket_id) {
    return ApiService.fetchData({
        url: `/operation/ticketing-module/stage/comments/${ticket_id}`,
        method: 'get'
    })
}

export async function apiDealerData(dealerID) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/${dealerID}`,
        method: 'get',
        // params,
    })
}


export async function apiGetMSVReachMasterData() {
    return ApiService.fetchData({
        url: `operation/msv-delay-reasons?msv_delay_type=MSV_Reach`,
        method: 'get'
    })
}

export async function apiGetMSVRecoveryMasterData() {
    return ApiService.fetchData({
        url: `operation/msv-delay-reasons?msv_delay_type=MSV_Recovery`,
        method: 'get'
    })
}


export async function apiCompleateDownloadExecutive(params) {
    try {
        const response = await ApiService.fetchData({
            url: `elastic/executive-ticketing/download`,
            method: 'get',
            params,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function apiCompleateDownloadDealer(params) {
    try {
        const response = await ApiService.fetchData({
            url: `elastic/dealer-ticketing/download`,
            method: 'get',
            params,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function apiSearchTechnicians(word) {
    return ApiService.fetchData({
        url: `operation/dealer-technician/search/${word}`,
        method: 'get'
    })
}

export async function updateApiShiftRoaster(data) {
    try {
        const resp = await ApiService.fetchData({
            url: `operation/shift-management`,
            method: 'put',
            data,
        })
        return {
            status: 'success',
            data: resp,
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data || errors.toString(),
        }
    }
}

export async function createApiShiftRoaster(data) {
    try {
        const resp = await ApiService.fetchData({
            url: `operation/shift-management`,
            method: 'post',
            data,
        })
        return {
            status: 'success',
            data: resp,
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data || errors.toString(),
        }
    }
}

export async function apiAllExecutivesData(params) {
    let url = 'operation/executive-details/statuswise-ticket-count'
    return ApiService.fetchData({
        url: url,
        method: 'get',
        params
    })
}
export async function apigetShiftRoasterConfig(params) {
    let url = 'operation/shift-management/getShiftConfig'
    return ApiService.fetchData({
        url: url,
        method: 'get',
        params
    })
}
export async function apiBulkAssign(params) {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/bulk-assign?` + params,
        method: 'patch',
    })
}
export async function apiPriorityList() {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/priority-enum`,
        method: 'get',
    })
}

export async function apiSearchForSuggestion(params) {
    return ApiService.fetchData({
        url: `account/acc-users/executive/suggestion-search?key=${params}`,
        method: 'get',
        // params,
    })
}
export async function apigetDealersSugessionsData(params) {
    return ApiService.fetchData({
        url: `operation/dealers-masters/service-station/search?key=${params}`,
        method: 'get'
    })
}


export async function apiGetStateTimeDifference(ticketID) {
    return ApiService.fetchData({
        url: `operation/can-parameter/state-time-difference/${ticketID}`,
        method: 'get',
    })
}

export async function apiUploadFile(data) {
    return ApiService.fetchData({
        url: `operation/ticketing/file-upload`,
        method: 'post',
        data,
    })
}

export async function apiUpdateFleetManager(data) {
    return ApiService.fetchData({
        //url: `operation/fleet-manager`,
        url: `operation/vehicle-masters/fleet-mgr`,
        method: 'put',
        data,
    })
}

export async function apiUpdateDriver(data) {
    return ApiService.fetchData({
        url: `operation/fleet-driver`,
        method: 'put',
        data,
    })
}

export async function apiFetchShiftRoasterCalenderData(req) {
    return ApiService.fetchData({
        url: `/operation/shift-management/getAbscentDays?id=${req.id}&month=${req.month}&year=${req.year}`,
        method: 'get',
    })
}

export async function apiFetchHoldReasons() {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/executive-hold-reasons`,
        method: 'get',
    })
}

export async function apiFetchCancelReasons() {
    return ApiService.fetchData({
        url: `operation/ticketing-modules/executive-cancel-reasons`,
        method: 'get',
    })
}

export async function apiAutoAssign(params) {
    return ApiService.fetchData({
        url: `operation/system-param-config/param-value`,
        method: 'patch',
         params
    })
}

export async function fetchAllRecordings(ticketId) {
    return ApiService.fetchData({
        url: `operation/ticket-dailer/recording/${ticketId}`,
        method: 'get',
    })
}