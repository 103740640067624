// DialerWidget.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialerWidgetVisible } from 'store/auth/sessionSlice';

function DialerWidget() {
  const { token, signedIn, showDialerWidget } = useSelector((state) => state?.auth?.session);
  const {
          email
      } = useSelector((state) => state.auth.user);
  const userUniqId = useSelector((state) => state?.auth?.user?.acc_unq_id)
  const dispatch = useDispatch()

  useEffect(() => {
    const checkAudioActivity = () => {
      const iframe = document.getElementById("dialer-widget");
      if (iframe) {
        const iframeDoc = iframe.contentDocument || iframe.contentWindow;
        if (!iframeDoc) return;

        // Look for an audio element in the iframe
        const audioElements = iframeDoc.getElementsByTagName("audio");
        for (let audio of audioElements) {
          if (!audio.paused) {
            dispatch(setDialerWidgetVisible(!showDialerWidget)) // Auto-open the dialer when ringing is detected
            return;
          }
        }
      }
    };

    // Poll every second to check for audio activity
    const interval = setInterval(checkAudioActivity, 1000);

    return () => clearInterval(interval); // Cleanup interval when component unmounts
  }, []);

  if (!signedIn || !email) return null;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: 'min(90vw, 350px)', // Max width: 350px, but adapts to smaller screens
        height: 'min(75vh, 500px)', // Max height: 500px, but adapts to viewport
        zIndex: 9999,
        boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
        display: showDialerWidget ? 'block' : 'none',
        borderRadius: '15px', // Rounded edges for a chat-like look
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      <iframe
        id="dialer-widget"
        src={`https://bharatbenz.wemacx.com/customapps/dicvwidget/index.php?email=${email}&wemacxtoken=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiYXBpdXNlciIsIm5hbWUiOiJzaXRldG9rZW4iLCJBUElfVElNRSI6MTczNTIwNDcyMH0.PxBGdi5fPUE-Efe7r2W0BbI1u-HmoIEABkTi_sLtmCI&token=${token}&tduser=${userUniqId}`}
        title="DialerWidget"
        allow="microphone; camera"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#999 transparent', // Thin scrollbar color for Firefox
        }}
      />
    </div>
  );
}

export default DialerWidget;
