import React, {
    forwardRef,
    useMemo,
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
    Table,
    Pagination,
    Select,
    Checkbox,
    Alert,
    Button,
} from 'components/ui';
import TableRowSkeleton from './loaders/TableRowSkeleton';
import { IoCloseOutline } from 'react-icons/io5';
import ColumnMenu from './ColumnsMenu';
import { SlOptionsVertical } from 'react-icons/sl';
import Loading from './Loading';
import { GrFormSearch } from 'react-icons/gr';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getExpandedRowModel,
    flexRender,
} from '@tanstack/react-table';
import { BiSort } from 'react-icons/bi';
import { LuMoveUp } from 'react-icons/lu';
import { LuMoveDown } from 'react-icons/lu';
import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { RiExpandUpDownLine } from 'react-icons/ri';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { useDispatch,useSelector } from 'react-redux';

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = (props) => {
    const {
        indeterminate,
        onChange,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        ...rest
    } = props;

    const ref = useRef(null);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]);

    const handleChange = (e) => {
        onChange(e);
        onCheckBoxChange?.(e);
        onIndeterminateCheckBoxChange?.(e);
    };

    return (
        <Checkbox
            className="mb-0 bg-white rounded shadow border-rounded"
            style={{ border: '2px solid grey' }}
            ref={ref}
            onChange={(_, e) => handleChange(e)}
            {...rest}
        />
    );
};

const Sorterss = ({ sort }) => {
    if (sort === 'asc') {
        return (
            <span className="mt-1 text-gray-500">
                <MdOutlineKeyboardArrowUp />
            </span>
        );
    } else if (sort === 'desc') {
        return (
            <span className="mt-1 text-gray-500">
                <MdOutlineKeyboardArrowDown />
            </span>
        );
    } else {
        return (
            <span className="mt-1 text-gray-500">
                <RiExpandUpDownLine />
            </span>
        );
    }
};

const DataTable = forwardRef((props, ref) => {
    const {
        skeletonAvatarColumns,
        columns: columnsProp,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        skeletonAvatarProps,
        pagingData,
        filterDisplay,
        columnVisiblityTable,
        closeFilter,
        searchFilter,
        onPin,
        onHide,
        onManage,
        showPagination,
        heightAuto = true,
        selectedRowId,
        isExpandable
    } = props;

    console.log(data)
console.log(isExpandable);
    const dispatch = useDispatch()

    const { pageSize, pageIndex, total } = pagingData;
    const [sorting, setSorting] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [menuColumnId, setMenuColumnId] = useState(null);
    const [manageColumns, setManageColumns] = useState(false);
    const [allColumnsForManagement, setAllColumnsForManagement] =useState(columnsProp);
    const [pinnedColumns, setPinnedColumns] = useState([]);
    const [expanded, setExpanded] = useState({});

    const pageSizeOption = useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number}/page`,
            })),
        [pageSizes]
    );

    const handleHideColumn = (columnId) => {
        const filteredColumns = visibleColumns.filter(
            (col) => col.id !== columnId
        );
        setVisibleColumns(filteredColumns);
        setAllColumnsForManagement(filteredColumns);
    };

    const handleManageColumns = () => {
        setManageColumns(true);
        setAllColumnsForManagement(visibleColumns);
    };

    const handleCheckBoxChange = (checked, row) => {
        onCheckBoxChange?.(checked, row);
    };

    const handleSearchFilter = (id) => {
        searchFilter(id);
    };

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        onIndeterminateCheckBoxChange?.(checked, rows);
    };

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page);
        }
    };

    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value));
        }
    };

    const handleSort = (id, direction) => {
        setSorting([{ id, desc: direction === 'desc' }]);
    };

    const handlePinColumn = (column, direction) => {
        setManageColumns(false);
        setMenuColumnId(null);
        if (column) {
            const currentPin = column.getIsPinned();
            if (direction === 'left') {
                column.pin(currentPin === 'left' ? false : 'left');
            } else if (direction === 'right') {
                column.pin(currentPin === 'right' ? false : 'right');
            } else {
                column.pin(false); // Unpin
            }
        }
        setPinnedColumns((prevPinnedColumns) => {
            const isAlreadyPinned = prevPinnedColumns.some(
                (pinned) => pinned.id === column.id
            );
            if (isAlreadyPinned) {
                return prevPinnedColumns.filter(
                    (pinned) => pinned.id !== column.id
                );
            }
            return [
                ...prevPinnedColumns,
                { id: column.id, width: column.getSize() },
            ];
        });
    };

    const getPinnedColumnLeft = (columnId) => {
        let left = 0;
        pinnedColumns
            .filter((pinned) => pinned.direction === 'left')
            .forEach((pinned) => {
                if (pinned.id === columnId) return;
                left += pinned.width;
            });
        return left;
    };

    const getPinnedColumnRight = (columnId) => {
        let right = 0;
        for (let pinned of [...pinnedColumns].reverse()) {
            if (pinned.id === columnId) break;
            right += pinned.width;
        }
        return right;
    };

    const hasOldColumnMetaKey = columnsProp.some(
        (col) => col.Header || col.accessor || col.Cell
    );
    const [visibleColumns, setVisibleColumns] = useState(columnsProp);

    const handleApplyColumns = (selectedColumnIds) => {
        const filteredColumns = columnsProp.filter((col) =>
            selectedColumnIds.includes(col.id)
        );
        setVisibleColumns(filteredColumns);
    };

    const expanderColumn = {
        id: 'expander',
        header: () => null,
        size: 30,
        cell: ({ row }) => {
            if (row.getCanExpand()) {
                return (
                    <span
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: 'pointer' },
                        }}
                    >
                        {row.getIsExpanded() ? (
                            <CiCircleMinus size={24} />
                        ) : (
                            <CiCirclePlus size={24} />
                        )}
                    </span>
                );
            }
            return null;
        },
        enableColumnFilter: false,
    };

    const finalColumns = useMemo(() => {
        const columns = visibleColumns;

        if (selectable) {
            return [
                expanderColumn,
                {
                    id: 'select',
                    size: '30',
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            onIndeterminateCheckBoxChange={(e) => {
                                handleIndeterminateCheckBoxChange(
                                    e.target.checked,
                                    table.getRowModel().rows
                                );
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                            onCheckBoxChange={(e) =>
                                handleCheckBoxChange(
                                    e.target.checked,
                                    row.original
                                )
                            }
                        />
                    ),
                    enableColumnFilter: false,
                },
                ...columns,
            ];
        }
        return [expanderColumn, ...columns];
    }, [visibleColumns, selectable]);

    const isDataEmpty = data.length === 0;

    const table = useReactTable({
        data,
        columns: hasOldColumnMetaKey ? [] : isExpandable?finalColumns:visibleColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => row.sub_module_dto || [],
        manualPagination: true,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            sorting,
            columnVisibility,
            expanded,
        },
        onExpandedChange: setExpanded,
    });

    const resetSorting = () => {
        table.resetSorting();
    };

    const resetSelected = () => {
        table.toggleAllRowsSelected(false);
    };

    useImperativeHandle(ref, () => ({
        resetSorting,
        resetSelected,
    }));

    if (hasOldColumnMetaKey) {
        const message =
            'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8';

        if (process.env.NODE_ENV === 'development') {
            console.warn(message);
        }

        return <Alert>{message}</Alert>;
    }

    const renderRow = (row, depth = 0) => {
        return (
            <React.Fragment key={row.id}>
                <Tr
                    style={{
                        backgroundColor: depth % 2 === 0 ? 'white' : '#f9f9f9',
                    }}
                >
                    {row.getVisibleCells().map((cell) => {
                        const isPinned = pinnedColumns.some(
                            (pinned) => pinned.id === cell.column.id
                        );
                        const pinnedLeft = isPinned
                            ? getPinnedColumnLeft(cell.column.id)
                            : undefined;
                        const pinnedRight = isPinned
                            ? getPinnedColumnRight(cell.column.id)
                            : undefined;
                        return (
                            <Td
                                key={cell.id}
                                style={{
                                    position: cell.column.getIsPinned()
                                        ? 'sticky'
                                        : 'relative',
                                    left:
                                        cell.column.getIsPinned() === 'left'
                                            ? getPinnedColumnLeft(
                                                  cell.column.id
                                              )
                                            : undefined,
                                    right:
                                        cell.column.getIsPinned() === 'right'
                                            ? getPinnedColumnRight(
                                                  cell.column.id
                                              )
                                            : undefined,
                                    paddingLeft: depth > 0 ? `${depth * 2}rem` : undefined,
                                    zIndex: cell.column.getIsPinned()
                                        ? 5
                                        : 'auto',
                                }}
                            >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </Td>
                        );
                    })}
                </Tr>
                {/* {row.getIsExpanded() &&
                    row.subRows.map((subRow) => renderRow(subRow, depth + 1))} */}
            </React.Fragment>
        );
    };

    return (
        <div className="flex h-full">
            <Loading
                loading={loading && data.length !== 0}
                type="cover"
                className="overflow-x-auto w-full"
            >
                <Table className="relative overflow-y-auto">
                    <THead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr
                                key={headerGroup.id}
                                className="bg-gray-100"
                                style={{ height: '30px' }}
                            >
                                {headerGroup.headers.map((header) => {
                                    const isPinned = pinnedColumns.some(
                                        (pinned) => pinned.id === header.id
                                    );
                                    const pinnedLeft = isPinned
                                        ? getPinnedColumnLeft(header.id)
                                        : undefined;
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                position: header.column.getIsPinned()
                                                    ? 'sticky'
                                                    : 'relative',
                                                left:
                                                    header.column.getIsPinned() ===
                                                    'left'
                                                        ? getPinnedColumnLeft(
                                                              header.id
                                                          )
                                                        : undefined,
                                                right:
                                                    header.column.getIsPinned() ===
                                                    'right'
                                                        ? getPinnedColumnRight(
                                                              header.id
                                                          )
                                                        : undefined,
                                                zIndex: header.column.getIsPinned()
                                                    ? 10
                                                    : 'auto',
                                                padding: '0.25rem 0.75rem',
                                            }}
                                        >
                                            {header.isPlaceholder &&
                                            header.column.columnDef
                                                .enableHidding ? null : (
                                                <div>
                                                    <div
                                                        className="flex"
                                                        style={{
                                                            width: `${
                                                                header.column
                                                                    .columnDef
                                                                    .size
                                                            }px`,
                                                            gap: '0.25rem',
                                                        }}
                                                    >
                                                        <div
                                                            className={classNames(
                                                                header.column.getCanSort() &&
                                                                    'cursor-pointer select-none point font-bold capitalize flex font-bold text-sm',
                                                                loading &&
                                                                    'pointer-events-none font-bold font-bold'
                                                            )}
                                                            style={{
                                                                textTransform:
                                                                    'none',
                                                                margin: '0',
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column
                                                                    .columnDef
                                                                    .header,
                                                                header.getContext()
                                                            )}
                                                        </div>
                                                        <div className="flex">
                                                            {header.column
                                                                .columnDef
                                                                .enableSearchFilter && (
                                                                <GrFormSearch
                                                                    className="text-xl"
                                                                    onClick={() =>
                                                                        handleSearchFilter(
                                                                            header
                                                                                .column
                                                                                .id
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            {header.column
                                                                .columnDef
                                                                .enableMultiOption && (
                                                                <SlOptionsVertical
                                                                    className="cursor-pointer mt-1"
                                                                    onClick={() =>
                                                                        setMenuColumnId(
                                                                            header.id
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            {menuColumnId ===
                                                                header.id && (
                                                                <ColumnMenu
                                                                    onSort={(
                                                                        direction
                                                                    ) =>
                                                                        handleSort(
                                                                            header.id,
                                                                            direction
                                                                        )
                                                                    }
                                                                    onPinLeft={() =>
                                                                        handlePinColumn(
                                                                            header.column,
                                                                            'left'
                                                                        )
                                                                    }
                                                                    onPinRight={() =>
                                                                        handlePinColumn(
                                                                            header.column,
                                                                            'right'
                                                                        )
                                                                    }
                                                                    onHide={() =>
                                                                        handleHideColumn(
                                                                            header.id
                                                                        )
                                                                    }
                                                                    onManage={
                                                                        handleManageColumns
                                                                    }
                                                                    isPinned={header.column.getIsPinned()}
                                                                    isHidden={
                                                                        header.hidden
                                                                    }
                                                                    onApplyColumns={
                                                                        handleApplyColumns
                                                                    }
                                                                    onClose={() => {
                                                                        setManageColumns(
                                                                            false
                                                                        );
                                                                        setMenuColumnId(
                                                                            null
                                                                        );
                                                                    }}
                                                                    columns={
                                                                        columnsProp
                                                                    }
                                                                    visibleColumns={
                                                                        visibleColumns
                                                                    }
                                                                    manageColumns={
                                                                        manageColumns
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {header.column.getCanFilter() && (
                                                                <div>
                                                                    {header.column.id.toLowerCase() ===
                                                                    'status' ? (
                                                                        <select
                                                                            value={
                                                                                header.column.getFilterValue() ||
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                header.column.setFilterValue(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }}
                                                                            className="border rounded p-1 w-full"
                                                                        >
                                                                            <option value="">
                                                                                All
                                                                            </option>
                                                                            <option value="ACTIVE">
                                                                                Active
                                                                            </option>
                                                                            <option value="IN_ACTIVE">
                                                                                Inactive
                                                                            </option>
                                                                        </select>
                                                                    ) : header.column.id.toLowerCase() ===
                                                                      'segment' ? (
                                                                        <select
                                                                            value={
                                                                                header.column.getFilterValue() ||
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                header.column.setFilterValue(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }}
                                                                            className="border rounded p-1 w-full"
                                                                        >
                                                                            <option value="">
                                                                                All
                                                                            </option>
                                                                            <option value="MDT R">
                                                                                MDT
                                                                                R
                                                                            </option>
                                                                            <option value="HDT C">
                                                                                HDT
                                                                                C
                                                                            </option>
                                                                            <option value="HDT R">
                                                                                HDT
                                                                                R
                                                                            </option>
                                                                        </select>
                                                                    ) : (
                                                                        <input
                                                                            type="search"
                                                                            value={
                                                                                header.column.getFilterValue() ||
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                header.column.setFilterValue(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            placeholder="search"
                                                                            className="border rounded p-1 w-full"
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}
                                                </div>
                                            )}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </THead>

                    {loading || data.length === 0 ? (
                        <TableRowSkeleton
                            columns={finalColumns.length}
                            rows={pagingData.pageSize}
                            avatarInColumns={skeletonAvatarColumns}
                            avatarProps={skeletonAvatarProps}
                        />
                    ) : (
                        <TBody>
                            {isDataEmpty ? (
                                <tr>
                                    <td colSpan={finalColumns.length}>
                                        <div className="flex w-screen flex-col items-center justify-center  h-60 text-gray-500">
                                            <p className="text-lg font-semibold">
                                                No Data Found
                                            </p>
                                            <p className="text-sm">
                                                Try adjusting your filters or
                                                refreshing the page.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                table
                                    .getRowModel()
                                    .rows.slice(0, pageSize)
                                    .map((row) => renderRow(row))
                            )}
                        </TBody>
                    )}
                </Table>

                {showPagination && (
                    <div className="flex items-center justify-between mt-1 border p-2 rounded">
                        <div className="flex space-x-4">
                            <div className="mr-0 m-2 flex items-center">
                                <p
                                    style={{
                                        color: '#6B7280',
                                        fontWeight: '600',
                                    }}
                                >
                                    Total {total} items
                                </p>
                            </div>
                            <Pagination
                                pageSize={pageSize}
                                currentPage={pageIndex}
                                total={total}
                                onChange={handlePaginationChange}
                            />
                        </div>
                        {!isDataEmpty && (
                            <Select
                                size="sm"
                                menuPlacement="top"
                                isSearchable={false}
                                value={pageSizeOption.filter(
                                    (option) => option.value === pageSize
                                )}
                                options={pageSizeOption}
                                onChange={(option) =>
                                    handleSelectChange(option.value)
                                }
                            />
                        )}
                    </div>
                )}
            </Loading>
        </div>
    );
});

DataTable.propTypes = {
    onPin: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onManage: PropTypes.func.isRequired,
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
    filterDisplay: PropTypes.bool,
    columnVisiblityTable: PropTypes.object,
    closeFilter: PropTypes.func,
    searchFilter: PropTypes.func,
    showPagination: PropTypes.bool,
};

DataTable.defaultProps = {
    pageSizes: [10, 25, 50, 100],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
    showPagination: true,
};

export default DataTable;
