import { apiGetFleetDriver, apiGetFleetManager, apiGetTicketingApproval, apiGetApprovalsDetails, apiSearchbyALL, apiTicketListData, apiGetDealerSugessionsData, ApigetSpocSugessionData, ApigetExecutiveSugessionData, apiGetTechnicianData, apiGetTechinciansugession, apiGetUserPlantCode, apiGetShiftRoasterData, apiSearchForSuggestion } from "services/ViewTicketService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// export const getTicketsData = createAsyncThunk(
//     'dashboards/data/getTicketsData',
//     async (data) => {
//         const response = await apiTicketDashboardData(data)
//         return response
//     }
// )


export const getSearchbyAll = createAsyncThunk(
    "viewTickets/data/getSearchbyAll",
    async (data) => {
        const response = await apiSearchbyALL(data)
        return response.data
    }
)
export const getSpocSugessionData = createAsyncThunk(
    "viewTickets/data/getSpocSugessionData",
    async (data) => {
        const response = await ApigetSpocSugessionData(data)
        return response.data
    }
)
export const getExecutiveSugessionData = createAsyncThunk(
    "viewTickets/data/getExecutiveSugessionData",
    async (data) => {
        const response = await ApigetExecutiveSugessionData(data)
        return response.data
    }
)
export const getTicketList = createAsyncThunk(
    'viewTickets/data/getTicketList',
    async (data) => {
        const response = await apiTicketListData(data)
        return response
    }
)
export const getTicketingApprovalData = createAsyncThunk(
    'viewTickets/data/getTicketingApprovalData',
    async (data) => {
        const response = await apiGetTicketingApproval(data)
        return response
    }
)
export const getKMValue = (number) => {
    console.log(number);
    if (number?.toString().length > 6) {
        let numberValue = (number / 1000).toFixed();
        return numberValue;
    } else {
        return number
    }
};

export const getApprovalTicketingData = createAsyncThunk(
    'viewTickets/data/getApprovalTicketingData',
    async (data) => {
        const response = await apiGetApprovalsDetails(data)
        return response
    }
)
export const getFleetManagerData = createAsyncThunk(
    'viewTickets/data/getFleetManagerData',
    async (data) => {
        const response = await apiGetFleetManager(data)
        return response
    }
)
export const getDateValue = (date, flag) => {
    if (date) {
        const dateObj = new Date(date);
        console.log(dateObj);
        // Format date as DD:MMM:YYYY
        const day = dateObj.getDate().toString().padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formattedDate = `${day} ${month} ${year}`;

        // Get hours and minutes
        let hours = dateObj.getHours();
        console.log(hours)
        const minutes = dateObj.getMinutes();
        console.log(minutes)
        // Determine AM/PM and convert to 12-hour format
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM

        // Return formatted value
        if (flag) {
            return (
                <span title={date}>
                    {formattedDate}
                </span>
            );
        } else {
            return (
                <span title={date}>
                    {formattedDate}, {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes} {period}
                </span>
            );
        }

    } else {
        return "";
    }
};

export const getDateOnly = (date) => {
    if (date) {
        const dateObj = new Date(date);
        // Format date as DD:MMM:YYYY
        const day = dateObj.getDate().toString().padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formattedDate = `${day} ${month} ${year}`;
        // Return formatted value
       return (<span title={date}>
            {formattedDate}
        </span>) 

    } else {
        return "";
    }
};

export const getUTCDateValue = (date) => {
    console.log(date)
    if (date) {
        var datevalue = new Date(date);
        // var now_utc = Date.UTC(datevalue.getUTCFullYear(), datevalue.getUTCMonth(),
        // datevalue.getUTCDate(), datevalue.getUTCHours(),
        // datevalue.getUTCMinutes(), datevalue.getUTCSeconds());
        console.log(datevalue.toISOString());
        return datevalue.toISOString();
    } else {
        return "";
    }
};

export const getFleetDriverData = createAsyncThunk(
    'viewTickets/data/getFleetDriverData',
    async (data) => {
        const response = await apiGetFleetDriver(data)
        return response
    }
)
export const getDealerDataSugessions = createAsyncThunk(
    'viewTickets/data/getDealerDataSugessions',
    async (data) => {
        const response = await apiGetDealerSugessionsData(data)
        return response
    }
)
export const getTechnicianData = createAsyncThunk(
    'viewTickets/data/getTechnicianData',
    async (data) => {
        const response = await apiGetTechnicianData(data)
        return response
    }
)
export const getTechinciansugession = createAsyncThunk(
    'viewTickets/data/getTechinciansugession',
    async (data) => {
        const response = await apiGetTechinciansugession(data)
        return response
    }
)
export const getUserPlantCode = createAsyncThunk(
    'viewTickets/data/getUserPlantCode',
    async (data) => {
        const response = await apiGetUserPlantCode(data)
        return response
    }
)
export const getShiftRoasterData = createAsyncThunk(
    'viewTickets/data/getShiftRoasterData',
    async (data) => {
        const response = await apiGetShiftRoasterData(data)
        return response
    }
)
export const getSearchForSuggestion = createAsyncThunk(
    'viewTickets/data/getSearchForSuggestion',
    async (data) => {
        const response = await apiSearchForSuggestion(data)
        return response
    }
)

// export const getTicketListCount = createAsyncThunk(
//     'viewTickets/data/getTicketListCount',
//     async (data) => {
//         const response = await apiTicketListCount(data)
//         return response
//     }
// )

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    sort: {
        Item: '',
        key: '',
    },
}

const dataSlice = createSlice({
    name: 'ccagenttl/data',
    initialState: {
        loading: false,
        tableData: initialTableData,
        selectedTab: 'All',
        ticketingApprovalStatus: [],
        fleetManagerData: {},
        fleetDriverData: {},
        dealerDataSugessions: [],
        technicianData: [],
        technicianSugessions: [],
        userPlantCode: [],
        shiftRoasterData: [],
        sugessionSearchData: []
    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setLoading: (state, action) => {
            state.tableData = action.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setTechnicianData: (state, action) => {
            state.technicianData = action.payload
        },



    },
    extraReducers: {
        [getTicketingApprovalData.fulfilled]: (state, action) => {
            state.ticketingApprovalStatus = action.payload
            state.loading = false
        },
        [getTicketingApprovalData.pending]: (state, action) => {
            state.loading = true
        },
        [getApprovalTicketingData.fulfilled]: (state, action) => {
            state.ticketingApprovalStatus = action.payload
            state.loading = false
        },
        [getApprovalTicketingData.pending]: (state, action) => {
            state.loading = true
        },
        [getFleetManagerData.fulfilled]: (state, action) => {
            state.fleetManagerData = action.payload
            state.loading = false
        },
        [getFleetManagerData.pending]: (state, action) => {
            state.loading = true
        },
        [getFleetDriverData.fulfilled]: (state, action) => {
            state.fleetDriverData = action.payload
            state.loading = false
        },
        [getFleetDriverData.pending]: (state, action) => {
            state.loading = true
        },
        [getDealerDataSugessions.fulfilled]: (state, action) => {
            state.dealerDataSugessions = action.payload
            state.loading = false
        },
        [getDealerDataSugessions.pending]: (state, action) => {
            state.loading = true
        },
        [getTechnicianData.fulfilled]: (state, action) => {
            state.technicianData = action.payload
            state.loading = false
        },
        [getTechnicianData.pending]: (state, action) => {
            state.loading = true
        },
        [getSearchForSuggestion.fulfilled]: (state, action) => {
            state.sugessionSearchData = action.payload
            state.loading = false
        },
        [getUserPlantCode.fulfilled]: (state, action) => {
            state.userPlantCode = action.payload
            state.loading = false
        },
        [getUserPlantCode.pending]: (state, action) => {
            state.loading = true
        },
        [getTechinciansugession.fulfilled]: (state, action) => {
            state.technicianSugessions = action.payload
            // state.loading = false
        },
        [getTechinciansugession.pending]: (state, action) => {
            // state.loading = true
        },
        [getShiftRoasterData.fulfilled]: (state, action) => {
            state.shiftRoasterData = action.payload
            // state.loading = false
        },
        [getShiftRoasterData.pending]: (state, action) => {
            // state.loading = true
        },
    }
})

export const { setTableData, setLoading, setSelectedTab, setTechnicianData } = dataSlice.actions
export default dataSlice.reducer