import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiSignIn,
    apiSignOut,
    apiSignUp,
    apiGetRolePermissions,
    apiGetThemeInfoByUnqid,
    apiGetOperaterSettingsID,
    apiGetBrandInfo,
    apiFetchDealerSpocData,
} from 'services/AuthService'
import { onFirstLogin, onSignInSuccess, onSignOutSuccess, setDashboardPermissions, setUserCategory } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import navigationConfigFile from 'configs/navigation.config'
import _ from 'lodash'
import { OpenNotification } from 'views/Servicefile'
import useDarkMode from 'utils/hooks/useDarkMode'
import useDirection from './useDirection'
import { setDateFormat, setLang, setLargeLogo, setSmallLogo, setThemeLargeLogo, setThemeSmallLogo } from 'store/locale/localeSlice'
import { setSideNavCollapse } from 'store/theme/themeSlice'
import { setSelectedTab } from 'views/Displaytickets/store/dataSlice'
import { userRoles } from 'utils/constants'
import { fetchDefaultMenuURL } from './MenuItems'

function useAuth() {

    const [isDark, setIsDark] = useDarkMode()
    const [direction, updateDirection] = useDirection()


    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn, accUnqId, userCategory } = useSelector((state) => state.auth.session)

    const getUserSpecificMenus = async (permissions, category) => {
        // const { permissions } = useSelector((state) => state.auth.user)

        console.log(navigationConfigFile)
        const allPermissions = _.flatMap(permissions?.module_dto, (module) => {
            return _.flatMap(module.sub_module_dto, (subModule) => {
                return {
                    module: module.code,
                    subModule: subModule.code,
                    permission: subModule.td_permissions_resp_dto,
                }
            })
        })
        console.log(allPermissions)
        const enabledPermissions = _.filter(allPermissions, (modulerow) => {
            if (
                modulerow.permission.filter((f) => f.enabled === true).length >
                0
            ) {
                modulerow.permission = modulerow.permission.filter(
                    (f) => f.enabled === true
                )
                return true
            } else {
                return false
            }
        })
        console.log(enabledPermissions)
        let moduleList = enabledPermissions.map((e) => e.module)
        let submoduleList = enabledPermissions.map((e) => e.subModule)
        // moduleList.push('DASHBOARD')
        //submoduleList.push('PBR')

        //needed this as there was a problem with menus not loading.
        let file = _.cloneDeep(navigationConfigFile)

        let listOfModulesFiltered = file.filter((module) => {

            if (['/viewtickets'].includes(module.path) && category === "PLATFORM_ADMIN") {
                return false
            }
            if (moduleList.includes(module.code)) {
                module.subMenu = module.subMenu.filter((sub) => {
                    return submoduleList.includes(sub.code)
                })

                return true
            } else {
                return false
            }
        })

        let permissionsList = enabledPermissions.map((e) =>
            e.permission.map((x) => x.code)
        )
        permissionsList = permissionsList ? permissionsList.flat() : []

        return { navigationConfig: listOfModulesFiltered, permissionsList }
    }

    // const signIn = async (values) => {
    //     try {
    //         const resp = await apiSignIn(values)

    //         if (resp.data) {
    //             const { access_token, user_name, acc_unq_id, role_name } =
    //                 resp.data
    //             dispatch(onSignInSuccess(access_token))
    //             const permissions = await apiGetRolePermissions({
    //                 //roleId: values.unq_id,

    //                 roleId: acc_unq_id,
    //             })

    //             let menuObject = await getUserSpecificMenus(permissions.data)

    //             //  if (resp.data.user) {
    //             dispatch(
    //                 setUser({
    //                     ...values,
    //                     user_name,
    //                     role_name,
    //                     permissionsList: menuObject.permissionsList,
    //                     navigationConfig: menuObject.navigationConfig,
    //                 })
    //             )

    //             //  }
    //             const redirectUrl = query.get(REDIRECT_URL_KEY)
    //             navigate(
    //                 redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
    //             )
    //             return {
    //                 status: 'success',
    //                 message: '',
    //             }
    //         }
    //     } catch (errors) {
    //         return {
    //             status: 'failed',
    //             message: errors?.response?.data?.message || errors.toString(),
    //         }
    //     }
    // }
    const FetchDealerSpocDetails = async (email) => {
        try {
            const res = await apiFetchDealerSpocData(email)
            //console.log('API Response:', res)

            if (res?.data?.success) {
                const dealerDetails = res.data?.response || {}
                localStorage.setItem('PlantCodes', JSON.stringify(dealerDetails));
                //setDealerSpocDetails(dealerDetails)
                console.log('Dealer SPOC Details:', dealerDetails)
                // if (role_type === userRoles.DEALER_SPOC_MANAGER) {
                //     fetchTickets(dealerDetails)
                // }
            } else {
                console.warn(
                    'API call unsuccessful:',
                    res?.data?.message || 'Unknown error'
                )
            }
        } catch (error) {
            console.error('Failed to fetch dealer SPOC details:', error)
        }
    }
    const signIn = async (values, flag) => {
        if (flag) {
            try {
                const resp = await apiSignIn(values)
                // if (resp.data.firsttime_login === true){
                //     const {
                //         access_token,
                //         user_name,
                //         acc_unq_id,
                //         role_name,
                //         acc_mno_id,
                //         acc_user_id,
                //         acc_mno_parent_unq_id,
                //         acc_mno_parent_id,
                //         user_type,
                //         unq_id,
                //         profile_img_url,
                //         acc_mno_unq_id
                //     } = resp.data

                //     // Dispatch the onSignInSuccess action
                //     await dispatch(
                //         onFirstLogin({
                //             access_token: access_token,
                //             acc_unq_id: acc_unq_id
                //         })
                //     );



                //    const permissions = await apiGetRolePermissions({
                //         roleId: acc_unq_id,
                //     })

                //     let menuObject = await getUserSpecificMenus(permissions.data)


                //     // let themeSettings = await apiGetThemeInfoByUnqid(unq_id)
                //     // // SET THE THEMES

                //     // let genSettings = await apiGetOperaterSettingsID(unq_id)

                //     // //check if there is atleast one record , then only apply settings..
                //     // if(themeSettings.data.response.length>0)
                //     // {
                //     //     let darkMode=themeSettings.data.response[themeSettings.data.response.length-1].thm_mode;

                //     //     setIsDark(darkMode ? 'dark' : 'light')
                //     // }

                //     // //applying direction
                //     // if(themeSettings.data.response.length>0){
                //     //     let direction=themeSettings.data.response[themeSettings.data.response.length-1].thm_orientation;
                //     //     updateDirection(direction)
                //     // }
                //     // let dateFormat;
                //     // if(genSettings?.data?.response.length>0){
                //     //     let lang = genSettings?.data.response[genSettings?.data.response.length-1].gen_set_lang_pref
                //     //     dispatch(setLang(lang))
                //     //     dateFormat = genSettings?.data.response[genSettings?.data.response.length-1].gen_set_date_format
                //     //     dispatch(setDateFormat(dateFormat))
                //     // }

                //     // //brand info logos
                //     // let brandInfo = await apiGetBrandInfo(acc_mno_unq_id)
                //     // if(brandInfo?.data?.response.length>0){
                //     //     let logo=brandInfo.data.response[brandInfo.data.response.length-1];
                //     //     dispatch(setLargeLogo(logo.large_logo_url))
                //     //     dispatch(setSmallLogo(logo.small_logo_url))
                //     //     dispatch(setThemeLargeLogo(logo.thm_large_logo_url))
                //     //     dispatch(setThemeSmallLogo(logo.thm_small_logo_url))
                //     // }

                //     // // Dispatch the setUser action
                //     await dispatch(
                //         setUser({
                //             ...values,
                //             user_name,
                //             role_name,
                //             acc_mno_id,
                //             acc_user_id,
                //             acc_unq_id,

                //             profile_img_url,
                //             permissionsList: menuObject.permissionsList,
                //             navigationConfig: menuObject.navigationConfig,
                //             user_type,
                //             unq_id
                //         })
                //     )

                //     await navigate('/change-password')


                //     return {
                //         status: 'success',
                //         message: '',
                //     }
                // }
                // else {
                const {
                    access_token,
                    user_name,
                    acc_unq_id,
                    role_name,
                    acc_mno_id,
                    acc_user_id,
                    acc_mno_parent_unq_id,
                    acc_mno_parent_id,
                    user_type,
                    unq_id,
                    profile_img_url,
                    acc_mno_unq_id,
                    refresh_token,
                    default_sub_mod_code,
                } = resp.data

                
                // Dispatch the onSignInSuccess action
                await dispatch(
                    onSignInSuccess({
                        access_token: access_token,
                        acc_unq_id: acc_unq_id,
                        refresh_token: refresh_token,

                    })
                );

                dispatch(setUserCategory(resp?.data?.user_role_category))
                const permissions = await apiGetRolePermissions({
                    roleId: acc_unq_id,
                })
                permissions?.data?.module_dto?.push({
                    "title": "Default",
                    "code": "DEF",
                    "description": "Default Module",
                    "sub_module_dto": [
                        {
                            "title": "DEF subModule",
                            "code": "DEFSUB",
                            "description": null,
                            "td_permissions_resp_dto": [
                                {
                                    "title": "MENU",
                                    "code": "DEFHQDMEN",
                                    "enabled": true
                                },
                                {
                                    "title": "VIEW",
                                    "code": "DEFHQDVIE",
                                    "enabled": true
                                },
                                {
                                    "title": "ADD",
                                    "code": "DEFHQDADD",
                                    "enabled": true
                                },
                                {
                                    "title": "EDIT",
                                    "code": "DEFHQDEDI",
                                    "enabled": true
                                },
                                {
                                    "title": "DEACTIVE",
                                    "code": "DEFHQDDEA",
                                    "enabled": false
                                },
                                {
                                    "title": "Default",
                                    "code": "DEFHQDDef",
                                    "enabled": false
                                }
                            ]
                        }
                    ]
                })
                dispatch(setDashboardPermissions(permissions))
                let menuObject = await getUserSpecificMenus(permissions.data, resp?.data?.user_role_category)


                // let themeSettings = await apiGetThemeInfoByUnqid(unq_id)
                // // SET THE THEMES

                // let genSettings = await apiGetOperaterSettingsID(unq_id)

                // //check if there is atleast one record , then only apply settings..
                // if(themeSettings.data.response.length>0)
                // {
                //     let darkMode=themeSettings.data.response[themeSettings.data.response.length-1].thm_mode;

                //     setIsDark(darkMode ? 'dark' : 'light')
                // }

                // //applying direction
                // if(themeSettings.data.response.length>0){
                //     let direction=themeSettings.data.response[themeSettings.data.response.length-1].thm_orientation;
                //     updateDirection(direction)
                // }
                // let dateFormat;
                // if(genSettings?.data?.response.length>0){
                //     let lang = genSettings?.data.response[genSettings?.data.response.length-1].gen_set_lang_pref
                //     dispatch(setLang(lang))
                //     dateFormat = genSettings?.data.response[genSettings?.data.response.length-1].gen_set_date_format
                //     dispatch(setDateFormat(dateFormat))
                // }

                // //brand info logos
                // let brandInfo = await apiGetBrandInfo(acc_mno_unq_id)
                // if(brandInfo?.data?.response.length>0){
                //     let logo=brandInfo.data.response[brandInfo.data.response.length-1];
                //     dispatch(setLargeLogo(logo.large_logo_url))
                //     dispatch(setSmallLogo(logo.small_logo_url))
                //     dispatch(setThemeLargeLogo(logo.thm_large_logo_url))
                //     dispatch(setThemeSmallLogo(logo.thm_small_logo_url))
                // }

                // Dispatch the setUser action
                await dispatch(
                    setUser({
                        ...values,
                        user_name,
                        role_name,
                        acc_mno_id,
                        acc_user_id,
                        acc_unq_id,
                        acc_mno_parent_unq_id,
                        acc_mno_parent_id,
                        profile_img_url,
                        permissionsList: menuObject.permissionsList,
                        navigationConfig: menuObject.navigationConfig,
                        user_type,
                        acc_mno_unq_id
                    })
                )
                console.log(resp?.data?.user_role_category)
                if (resp?.data?.user_role_category === userRoles.DEALER_SPOC_MANAGER || resp?.data?.user_role_category === userRoles.DEALER_SPOC) {
                    FetchDealerSpocDetails(values.email)
                } else {
                    localStorage.removeItem("PlantCodes")

                }
                //console.log(permissions.data)
                console.log(default_sub_mod_code)
                //let prrdata = permissions?.data?.module_dto;
                let defaultMenuCode = "HQD";
                // prrdata?.map(prrItem => {
                //     prrItem?.sub_module_dto?.map(subItem => {
                //         if (subItem?.td_permissions_resp_dto[5]?.enabled) {
                //             defaultMenuCode = subItem.code;
                //         }
                //     })
                // })
                if(default_sub_mod_code){
                    defaultMenuCode =default_sub_mod_code;
                }
                let redirectUrl = await fetchDefaultMenuURL(defaultMenuCode)
                // setTimeout(() => {
                //     redirectUrl = "/operationsMenu-menu-item2"
                // }, 2000)
                await dispatch(setSelectedTab("All"))
                console.log(redirectUrl)
                await navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )


                return {
                    status: 'success',
                    message: '',
                }
                // }
            } catch (errors) {


                return {
                    status: 'failed',
                    error: errors.response.data.error,
                    message: errors?.response?.data?.error.error_msg || errors.toString(),
                }
            }

        } else {
            try {
                if (values) {
                    const {
                        access_token,
                        user_name,
                        acc_unq_id,
                        role_name,
                        acc_mno_id,
                        acc_user_id,
                        acc_mno_parent_unq_id,
                        acc_mno_parent_id,
                        user_type,
                        unq_id,
                        profile_img_url,
                        acc_mno_unq_id,
                        refresh_token,
                        default_sub_mod_code,
                    } = values.data

                    // Dispatch the onSignInSuccess action
                    await dispatch(
                        onSignInSuccess({
                            access_token: access_token,
                            acc_unq_id: acc_unq_id,
                            refresh_token: refresh_token,

                        })
                    );

                    dispatch(setUserCategory(values?.data?.user_role_category))
                    let permissions = await apiGetRolePermissions({
                        roleId: acc_unq_id,
                    })

                    permissions?.data?.module_dto?.push({
                        "title": "Default",
                        "code": "DEF",
                        "description": "Default Module",
                        "sub_module_dto": [
                            {
                                "title": "DEF subModule",
                                "code": "DEFSUB",
                                "description": null,
                                "td_permissions_resp_dto": [
                                    {
                                        "title": "MENU",
                                        "code": "DEFHQDMEN",
                                        "enabled": true
                                    },
                                    {
                                        "title": "VIEW",
                                        "code": "DEFHQDVIE",
                                        "enabled": true
                                    },
                                    {
                                        "title": "ADD",
                                        "code": "DEFHQDADD",
                                        "enabled": true
                                    },
                                    {
                                        "title": "EDIT",
                                        "code": "DEFHQDEDI",
                                        "enabled": true
                                    },
                                    {
                                        "title": "DEACTIVE",
                                        "code": "DEFHQDDEA",
                                        "enabled": true
                                    },
                                    {
                                        "title": "Default",
                                        "code": "DEFHQDDef",
                                        "enabled": false
                                    }
                                ]
                            }
                        ]
                    })
                    dispatch(setDashboardPermissions(permissions))
                    let menuObject = await getUserSpecificMenus(permissions.data)

                    // Dispatch the setUser action
                    await dispatch(
                        setUser({
                            ...values,
                            user_name,
                            role_name,
                            acc_mno_id,
                            acc_user_id,
                            acc_unq_id,
                            acc_mno_parent_unq_id,
                            acc_mno_parent_id,
                            profile_img_url,
                            permissionsList: menuObject.permissionsList,
                            navigationConfig: menuObject.navigationConfig,
                            user_type,
                            acc_mno_unq_id
                        })
                    )
                    console.log(values?.data?.user_role_category)
                    if (values?.data?.user_role_category === userRoles.DEALER_SPOC_MANAGER || values?.data?.user_role_category === userRoles.DEALER_SPOC) {
                        FetchDealerSpocDetails(values.data.email)
                    } else {
                        localStorage.removeItem("PlantCodes")

                    }

                    console.log(default_sub_mod_code)
                    //let prrdata = permissions?.data?.module_dto;
                    let defaultMenuCode = "HQD";
                    // prrdata?.map(prrItem => {
                    //     prrItem?.sub_module_dto?.map(subItem => {
                    //         if (subItem?.td_permissions_resp_dto[5]?.enabled) {
                    //             defaultMenuCode = subItem.code;
                    //         }
                    //     })
                    // })
                    if(default_sub_mod_code){
                        defaultMenuCode =default_sub_mod_code;
                    }
                    let redirectUrl = await fetchDefaultMenuURL(defaultMenuCode)
                    await dispatch(setSelectedTab("All"))
                    await navigate(
                        redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    )


                    return {
                        status: 'success',
                        message: '',
                    }
                }
            } catch (errors) {


                return {
                    status: 'failed',
                    error: errors.response.data.error,
                    message: errors?.response?.data?.error.error_msg || errors.toString(),
                }
            }

        }



    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {

                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
        dispatch(setSideNavCollapse(false))

    }

    
    return {
        authenticated: token && accUnqId && signedIn,
        signIn,
        signUp,
        signOut,
    }
}


export default useAuth
