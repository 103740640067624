import { useSelector, useDispatch } from 'react-redux'

export async function fetchDefaultMenuURL(menuCode) {
    console.log(menuCode)
    switch (menuCode) {
        case 'MUP':
            return "/masterManagement-menu-item1"
            break
        case 'MDV':
            return "/masterManagement-menudata-item2"
            break
        case 'HQD':
            return "/dashboard-management"
            break
        case 'DTCDB':
            return "/operationsMenu-menu-item2"
            break
        case 'TLS':
            return "/viewtickets"
            break
        case 'TTS':
            return "/track-trace"
            break
        case 'MYA':
            return "/account-menu-item-view-1/users"
            break
        
    }
}
